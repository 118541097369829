<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">CREAR ADMINISTRADOR</p>
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline is-centered">
          <div class="column is-11">
            <label class="label">Nombre</label>
            <input
              class="input"
              type="text"
              placeholder="Nombre"
              v-model="name"
              name="name"
              v-validate="'alpha_spaces|required'"
              data-vv-as="del nombre"
            >
            <span v-show="vverrors.has('name')" class="help is-danger">{{ vverrors.first('name') }}</span>
          </div>
          <div class="column is-11">
            <label class="label">Apellidos</label>
            <input
              class="input"
              type="text"
              placeholder="Apellidos"
              v-model="lastName"
              name="lastName"
              v-validate="'alpha_spaces|required'"
              data-vv-as="del apellido"
            >
            <span v-show="vverrors.has('lastName')" class="help is-danger">{{ vverrors.first('lastName') }}</span>
          </div>
          <div class="column is-11">
            <label class="label">Correo electrónico</label>
            <input
              class="input"
              type="text"
              placeholder="Correo electrónico"
              v-model="email"
              name="email"
              v-validate="'email|required'"
              data-vv-as="del correo electrónico"
            >
            <span v-show="vverrors.has('email')" class="help is-danger">{{ vverrors.first('email') }}</span>
          </div>
          <div class="column is-11 is-flex is-align-items-center">
            <label class="label mr-5 mb-0">Estatus</label>
            <span>
              <input
                id="switchStatus"
                type="checkbox"
                class="switch is-rounded is-small"
                :checked="status ? 'checked' : ''"
                v-model="status"
                name="switchStatusStatus"
              >
              <label for="switchStatus"></label>
            </span>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark_outlined"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="$emit('close-modal')"
        >
          CANCELAR
        </button>
        <button
          class="button button_micro_dark"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="createUser"
        >
          CREAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalAddAdminUser',
  data () {
    return {
      name: null,
      lastName: null,
      email: null,
      status: false,
      loading: false
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    successfulCreationUser: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    ...mapActions(['createUserAdmin']),
    async createUser () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          name: this.name,
          lastName: this.lastName,
          email: this.email,
          active: this.status
        }
        const resp = await this.createUserAdmin(data)
        if (resp) {
          this.successfulCreationUser()
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }
</style>
